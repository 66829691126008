body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@keyframes border-dance {
  0% {
    background-position: left top, right top, right bottom, left bottom;
  }
  100% {
    background-position: right top, right bottom, left bottom, left top;
  }
}

.pigeon-attribution {
  display: none;
}
